body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
img {
  /* image-rendering:-moz-crisp-edges;
  image-rendering:-o-crisp-edges; */
  image-rendering:-webkit-optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
  -webkit-font-smooting:  antialiased;
}

.App {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  position: relative;
  width: 100%;
  min-height: 130vh;
  background-image: linear-gradient(25deg, #CE9FFC, #7367F0, #F97794, #623AA2);
}

.main-containter {
  margin: auto 0;
  height: 8rem;
  width: 90%;
  background-color: rgba(56, 56, 56, 0.5);
}


.top-image {
  width: 100%;
  height: 1.8rem;
  position: absolute;
}

.right-header {
  display: flex;
  align-items: center;
}

.header-container {
  display: flex;
  justify-content: center;
  /* height: 1.5rem; */
}

.header-wrapper {
  display: flex;
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin-top: 0.4rem;
}

.header-title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-image {
  width: 3.8rem;
}

.subtitle-image {
  margin-top: 0.1rem;
  width: 3.4rem;
}

.box-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0.1rem;
}

.box-wrapper {
  width: 70%;
  display: flex;
  justify-content: space-between;
}

.bottom-image {
  width: 100%;
  height: 1.5rem;
  position: absolute;
}

.box-image-item {
  width: 2.5rem;
  height: 2.5rem;
}

.main-wrapper {
  width: 80%;
  margin: 0 auto;

}

.bottom-background {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* height: 2.5rem;   */
  margin-top: 0.8rem;
  height: 1.5rem;
}

.footer {
  width: 90%;
  display: flex;
  justify-content: center;
}

.footer-wrapper {
  flex: 1;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.title {
  -webkit-mask: linear-gradient(to top, transparent, rgb(0, 0, 0));
}
